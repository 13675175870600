import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

const Overview = ({ industries }) => {
  const [active, setActive] = useState(false);

  setTimeout(() => {
    setActive(true);
  }, 1200);

  useEffect(() => {
    const handler = () => {
      setActive(false);
      setTimeout(() => {
        setActive(true);
      }, 1000);
    };

    const upperClass = document.getElementsByClassName(
      "industry_upper_class"
    )[0];

    upperClass.addEventListener("click", handler);

    return () => {
      upperClass.removeEventListener("click", handler);
    };
  });

  return (
    <div className="overviewSolutions">
      {industries.map((val, index) => (
        <VisibilitySensor
          active={active}
          onChange={(isVisible) => {
            if (isVisible) {
              navigate(`/industries/#${val?.id}`);
            }
          }}
        >
          <div className="containerwrap scrollHotspot" key={index} id={val.id}>
            <div className="overviewSectionSolutions">
              <div className="overviewTitleSolutions">{val.title}</div>
            </div>
            <div className="overviewContentSolutions">
              {val.des.map((val, index) => (
                <div
                  className="overviewContentLeftSolutions d-flex justify-content-center"
                  key={index}
                >
                  <p className="w-text-90">{val}</p>
                </div>
              ))}
            </div>
          </div>
        </VisibilitySensor>
      ))}
    </div>
  );
};

export default Overview;
