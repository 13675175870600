import React from "react";
import { navigate,Link } from 'gatsby';
import Navigation from "./Navigation";

const Heading = ({ herosection, locale, navbar }) => {
    return (
        <div className="industry_upper_class">
            <div className="serviceHeroSection" id="heading">
                <div className="containerwrap">
                    <div className="serviceHeroSectionTitle">{herosection.title}</div>
                    <h1 className="serviceHeroSectionHeading">{herosection.heading}</h1>
                    
                
                    <div className="opsBtnwrap middelBtn">
                        <Link className="opsBtnlink" to={`/contact-us/`}>
                            <p>{herosection.btn}</p>
                        </Link>
                    </div>
                </div>
            </div>
            <Navigation data={navbar} locale={locale} />
        </div>
    )
}

export default Heading;